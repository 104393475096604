/* eslint-disable max-lines */
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import auth from '../../graphql/query/Auth'
import { locationType } from '../../graphql/enums/locationType'
import { getAuthCookie, removeAuthCookie, setAuthCookie } from '../../helpers/CookieHelpers'
import { redirect } from '../../helpers/UrlHelpers'
import { pushToDataLayer } from '../../helpers/GTMHelpers'
import routes, { Router } from '../../routes'
import ApiSagas from '../api/sagas'
import { pageType, selectors as AppSelectors } from '../app/redux'
import types from '../../components/OnBoarding/types'
import ChatSagas from '../chat/sagas'

import { actions, selectors } from './redux'


const DEBUG = false
const log = DEBUG ? console.log : () => null

export default class AuthSagas {

  static req = null

  static* init(ctx) {
    const cookie = getAuthCookie(ctx?.req)
    const token = yield select(selectors.token)

    log('Auth: init', { cookie: !!cookie?.access_token, token: !!token?.access_token })

    // clear user
    if (!process.browser) {
      yield put(actions.clear())
    }

    if (cookie) {
      if (!token || token?.access_token !== cookie?.access_token) {
        yield call(AuthSagas.tokenProcess, cookie, ctx?.req)
      }
    } else if (token) {
      yield put(actions.clear())
    }

    yield call(ChatSagas.updateChatSubscription)
  }

  static* redirectToRegisterWelcome(res) {
    const link = Router.getRouteUrl(routes.userRegisterWelcome)

    yield call(redirect, res, link, 303)
  }

  static* redirectToRegisterPremium(res) {
    const link = Router.getRouteUrl(routes.userRegisterPremium)

    yield call(redirect, res, link, 303)
  }

  static* redirectToRegisterPremiumWithDiscount(discount, res) {
    const link = Router.getRouteUrl(routes.userRegisterPremium, { discount })

    yield call(redirect, res, link, 303)
  }

  static* redirectToSubscribe(res) {
    const link = Router.getRouteUrl(routes.userSubscription)

    yield call(redirect, res, link, 303)
  }

  static* redirectToDashboard(res) {
    // const dashboardLink = Router.getRouteUrl(routes.userDashboard)
    // temporary redirect to events
    const dashboardLink = Router.getRouteUrl(routes.userEvents)

    yield call(redirect, res, dashboardLink, 303)
  }

  static* redirectToProfile(res) {
    const profileLink = Router.getRouteUrl(routes.userProfile)

    yield call(redirect, res, profileLink, 303)
  }

  static* redirectToLogin(res) {
    const pages = yield select(AppSelectors.pages)
    const loginLink = Router.getRouteUrl(routes.page, { slug: pages[pageType.LOGIN].link })

    yield call(redirect, res, loginLink, 303)
  }

  // eslint-disable-next-line complexity
  static* redirectLogged(ctx) {
    const isConnected = yield select(selectors.isConnected)
    const pages = yield select(AppSelectors.pages)
    const { asPath, pathname, query, res } = ctx

    const redirectToDashboardPages = [
      // pageType.ON_BOARDING,
      pageType.FORGOT,
      pageType.LOGIN,
      pageType.RESET,
      pageType.MANAGER_ACTIVATION,
    ]
      .filter((page) => pages[page])
      .map((page) => Router.getRouteUrl(routes.page, { slug: pages[page]?.link }))

    const redirectToLoginRoutes = Object.values(routes)
      .filter((route) => route.auth)
      .map((route) => route.component)

    const redirectToLoginPages = []
      .filter((page) => pages[page])
      .map((page) => Router.getRouteUrl(routes.page, { slug: pages[page]?.link }))

    const redirectToSubscribePages = []
      .filter((page) => {
        if (page === pageType.DIRECTORY) {
          return query.type === 'PROFILE' && pages[page]
        }
        return pages[page]
      })
      .map((page) => Router.getRouteUrl(routes.page, { slug: pages[page]?.link }))

    const redirectToSubscribe = Object.values(routes)
      .filter((route) => route.premium)
      .map((route) => route.component)

    if (asPath === '' || asPath === '/') {
      return
    }

    const user = yield select(selectors.user)

    const nonPremiumPages = Object.values(routes)
      .filter((route) => !route.premium)
      .map((route) => route.component)

    // Premium payment redirect
    if (pathname !== routes.userRegisterPremium.component
      && isConnected
      && user?.company?.is_premium
      && (!user?.company?.isActivePremium)
      && !nonPremiumPages.includes(pathname)) {
      log('Auth: redirect to premium activation')
      yield call(AuthSagas.redirectToRegisterPremium, res)
      return true
    }

    if (isConnected && redirectToDashboardPages.includes(asPath)) {
      log('Auth: redirect to user dashboard')
      yield call(AuthSagas.redirectToDashboard, res)
      return true
    }

    if (
      !isConnected
      && (redirectToLoginRoutes.includes(pathname) || redirectToLoginPages.includes(asPath))
    ) {
      log('Auth: redirect to login')
      yield call(AuthSagas.redirectToLogin, res)
      return true
    }

    if (
      isConnected
      && !user?.company?.isActivePremium
      && (redirectToSubscribePages.includes(asPath?.replace(/\?.*/, '')) || redirectToSubscribe.includes(pathname))
    ) {
      yield call(AuthSagas.redirectToSubscribe, res)
      return true
    }
  }

  static* register({ payload }) {
    const values = payload.values
    const isPremium = values.type === types.PREMIUM
    const input = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      password: values.password,
      phone: values.phone,
      company: values.companyName,
      location: values.location,
      // eslint-disable-next-line max-len
      siret: values.location === locationType.FRANCE ? values.companyNumber : (values.location === locationType.MONACO ? values.companyMonacoNumber : values.companyTvaEuropeNumber),
      cgu: values.cgu,
      newsletter: values.optin,
      premium: isPremium,
    }

    const result = yield call(ApiSagas.query, auth.register, { input })

    if (result.errors && result.errors.length) {
      yield put(actions.registerError(result.errors))
    } else {
      yield put(actions.registerSuccess(result.data.register))

      pushToDataLayer({
        event: 'TPV',
        Pagevuevirtuelle: `/inscription/${isPremium ? 'premium' : 'freemium'}/confirmation`,
      })
    }
  }

  static* login({ payload }) {
    const values = payload.values
    const input = {
      email: values.email,
      password: values.password,
    }

    const result = yield call(ApiSagas.query, auth.login, { input })

    if (result.errors && result.errors.length) {
      yield put(actions.loginError(result.errors))
    } else {
      yield call(AuthSagas.tokenProcess, result.data.login)
      yield call(ChatSagas.updateChatSubscription)
      yield call(AuthSagas.redirectToDashboard)
      yield put(actions.loginSuccess(result.data.login))
    }
  }

  static* forgot({ payload }) {
    const values = payload.values
    const { email } = values

    const result = yield call(ApiSagas.query, auth.forgot, { email })

    if (result.errors && result.errors.length) {
      yield put(actions.forgotError(result.errors))
    } else {
      yield put(actions.forgotSuccess(result.data.forgot))
    }
  }

  static* reset({ payload }) {
    const values = payload.values
    const route = yield select(AppSelectors.route)
    const { token } = route.query
    const { email, password } = values

    const result = yield call(ApiSagas.query, auth.reset, { email, token, password })

    if (result.errors && result.errors.length) {
      yield put(actions.resetError(result.errors))
    } else {
      yield put(actions.resetSuccess(result.data.reset))
      yield put(actions.login(values))
    }
  }

  static* manager({ payload }) {
    const route = yield select(AppSelectors.route)
    const { email, expires, hash, id, signature } = route?.query
    const values = payload.values
    const input = {
      // first_name: values.firstName,
      // last_name: values.lastName,
      cgu: values.cgu,
      password: values.password,
      phone: values.phone,
      newsletter: values.optin,
      company_function: values.role,
      email,
      expires,
      hash,
      id,
      signature,
    }

    const result = yield call(ApiSagas.query, auth.activateManager, { input })

    if (result.errors && result.errors.length) {
      yield put(actions.managerError(result.errors))
    } else {
      yield call(AuthSagas.tokenProcess, result.data.activateManagerAccount)
      yield call(ChatSagas.updateChatSubscription)
      yield call(AuthSagas.redirectToProfile)
      yield put(actions.managerSuccess(result.data.activateManagerAccount))
    }
  }

  static* logout() {
    if (process.browser && localStorage) {
      localStorage.clear()
    }
    yield all([
      call(AuthSagas.tokenProcess),
      call(ChatSagas.updateChatSubscription),
      call(ApiSagas.query, auth.logout),
      call(redirect, null, '/', 303),
    ])
  }

  static* tokenProcess(token, req) {
    yield put(actions.setToken(token))

    if (token?.access_token) {
      const result = yield call(ApiSagas.query, auth.me)

      if (result?.data?.me) {
        log('Auth: save new token', result?.data?.me?.email)
        yield put(actions.setUser(result?.data?.me))
        setAuthCookie(token, req)
        return true
      }
      yield put(actions.setToken(null))
    }

    log('Auth: Remove token, remove user')
    removeAuthCookie(req)
    yield put(actions.setUser(null))
    return false
  }


  static* loop() {
    yield all([
      takeLatest(actions.register.getType(), AuthSagas.register),
      takeLatest(actions.login.getType(), AuthSagas.login),
      takeLatest(actions.logout.getType(), AuthSagas.logout),
      takeLatest(actions.forgot.getType(), AuthSagas.forgot),
      takeLatest(actions.reset.getType(), AuthSagas.reset),
      takeLatest(actions.manager.getType(), AuthSagas.manager),
    ])
  }

}
