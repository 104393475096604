const filtersSlugTypes = [
  'type',
  'categories',
  'budget',
  'deadline',
  'source',
]

const getFilterSlugRightField = {
  type: 'type',
  categories: 'categories',
  budget: 'budget',
  deadline: 'deadline',
  source: 'source',
}

export const regexOffersFilters = new RegExp(`(?:\\/(?:(?:(${filtersSlugTypes.join('|')})-([\\w\\d-]+))))`, 'g')

export const transformOffersSlugFilters = (asPath) => {
  let matches
  const slugFilters = {}

  while ((matches = regexOffersFilters.exec(asPath)) !== null) {
    const property = getFilterSlugRightField[matches[1]]

    if (!Object.prototype.hasOwnProperty.call(slugFilters, property)) {
      slugFilters[property] = matches[2]
    } else {
      if (!Array.isArray(slugFilters[property])) {
        slugFilters[property] = [slugFilters[property]]
      }
      slugFilters[property].push(matches[2])
    }
  }
  return slugFilters
}
